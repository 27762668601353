a {
  font-family: $font-medium;
  color: $color-black;
  font-size: 16px;
  transition: color 250ms linear;

  &:hover {
    color: black;
    transition: color 250ms linear;
  }
}

.btn {
  outline: none;
  border: initial;
  font-family: $font;
  font-size: 16px;
  color: $color-black;
  background: $almost-light-grey;
  border-radius: 7px;
  padding: 10px 15px;
  align-self: flex-start;
}
