td,
tr,
th,
tbody {
  margin: 0;
  padding: 0;
}

table {
  vertical-align: top;
}

a {
  color: inherit;
  text-decoration: none;
}

.first-table {
  width: 100%;
  max-width: 500px;
  margin: 0;
  padding-top: 5px;
  margin-bottom: 10px;
  font-family: Arial, sans-serif;
  font-size: 12px;
  display: block;
  border-top: 1px solid #bababa;

  .first-table__logo {
    height: 180px;
    width: auto;
  }

  .first-table__separator {
    font-size: 1px;
    line-height: 1px;
    border: 0px;
    padding: 0px;
    width: 10px;
  }

  .nom_prenom {
    color: #11486f;
    margin: 0;
    font-size: 16px;
  }

  .poste {
    color: #323232;
    margin: 0;
    margin-top: 3px;
    font-size: 11px;
  }

  .first-table__infos {
    color: #000000;
    margin: 0;
    margin-top: 10px;
    font-size: 14px;

    .infos__number {
      color: #000000;
      text-decoration: underline;
      font-weight: bold;
      opacity: 0.7;
    }
  }

  .urgence-constat {
    color: #cd4142;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    display: block;
  }

  .link-blue {
    color: #11486f;
    margin: 0;
    font-size: 13px;
  }
}

.second-table {
  width: 500px;
  max-width: 500px;
  margin: 0;
  padding-top: 5px;
  font-family: Arial, sans-serif;
  font-size: 11px;
  display: block;
  border-top: 1px solid #f2f2f2;

  .second-table__bandeau {
    color: #000000;
    font-size: 12px;
    margin: 0;
    margin-top: 5px;
    line-height: 1.4;
  }
}
