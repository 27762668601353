.block-admin {
  width: 1600px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 80px;
  @media (max-width: $laptop) {
    width: 80vw;
  }

  .block-admin__title {
    font-family: $font-bold;
    color: $color-very-black;
    padding-bottom: 10px;
    font-size: 24px;
    border-bottom: 2px solid $color-very-black;
    margin-bottom: 50px;
  }

  .admin-signature {
    background: $almost-light-grey;
    padding: 25px 35px;
    border-radius: 5px;

    &:not(:last-child) {
      margin-bottom: 30px;
    }

    .admin-signature__infos {
      margin-bottom: 25px;

      .infos__name {
        font-family: $font-bold;
        color: $color-very-black;
        font-size: 20px;
        margin-bottom: 10px;
      }

      .infos__poste {
        font-family: $font;
        color: $color-very-black;
        font-size: 16px;
      }
    }

    .admin-signature__btn-delete {
      p {
        color: $color-very-black;
        font-family: $font-medium;
        font-size: 14px;
        opacity: 0.7;
        transition: opacity 250ms;
        cursor: pointer;

        &:hover {
          opacity: 1;
          transition: opacity 250ms;
        }
      }
    }
  }

  .block-admin__add {
    margin-top: 50px;

    label {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 30px;
      }

      span {
        font-family: $font;
        font-size: 14px;
        color: $color-very-black;
      }

      input {
        margin-top: 10px;
        outline: none;
        border: initial;
        border-bottom: 1px solid $color-very-black;
        background: transparent;
        font-family: $font;
        font-size: 14px;
      }

      .entities {
        width: 100%;

        .entities__entity {
          cursor: pointer;
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;
          padding: 15px 0px;
          background-color: $light-grey;
          border-radius: 5px;
          transform: translateY(0px);
          transition: background 250ms linear, box-shadow 250ms linear,
            transform 250ms ease-in-out, padding 250ms ease-in-out;

          p {
            font-family: $font-medium;
            font-size: 16px;
          }

          &:not(.selected):hover {
            padding: 15px 10px;
            background: $almost-light-grey;
            box-shadow: 0px 10px 15px -5px rgba(0, 0, 0, 0.25);
            transform: translateY(-5px);
            transition: background 250ms linear, box-shadow 250ms linear,
              transform 250ms ease-in-out, padding 250ms ease-in-out;
          }

          &:first-child {
            margin-top: 10px;
          }

          &.selected {
            margin-bottom: 15px;
            padding-bottom: 14px;
            border-bottom: 1px solid $color-black;
            border-radius: 0px;

            .entity__name {
              font-size: 20px;
              font-family: $font;
            }
          }
        }
      }
    }

    .full {
      width: 100%;
      display: flex;
      justify-content: space-between;

      label {
        width: 49%;
      }
    }
  }
}

.btn-submit {
  margin-top: 25px;
  background: transparent;
  border: initial;
  outline: none;
  font-family: $font-medium;
  font-size: 16px;
  padding: 0;
  padding-bottom: 5px;
  border-bottom: 1px solid $color-very-black;

  &:after {
    content: "-->";
    position: relative;
    margin-left: 5px;
    transition: margin-left 250ms ease-in-out;
  }

  &:hover {
    &:after {
      margin-left: 10px;
      transition: margin-left 250ms ease-in-out;
    }
  }
}
